<template>
  <v-container>
    <v-layout row wrap justify-space-between class="mb-2">
      <v-flex xs6 sm4 md4 lg4 class="mb-1">
        <v-btn
          :color="$store.state.secondaryColor"
          outlined
          small
          @click="goBack"
        >
          <v-icon small left>mdi-arrow-left</v-icon>
          <span class="caption">back</span>
        </v-btn>
      </v-flex>
    </v-layout>
    <h2>Receipts</h2>
  </v-container>
</template>
<script>
export default {
  data: () => ({}),
  methods: {
    goBack() {
      this.$router.push({
        name: "DashboardAccount",
      });
    },
  },
};
</script>
